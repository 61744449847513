<template>
  <div class="d-page-wrapper d-disclaimer-page">
    <div class="d-disclaimer-page-title">
      {{ $t('disclaimerPage.title') }}
    </div>

    <div
      v-html="$t('disclaimerPage.text', { dealer: currentDealer.name })"
      class="d-disclaimer-page-text"
    ></div>

    <v-checkbox
      v-model="isAccepted"
      class="mt-10"
    >
      <template v-slot:label>
        <span class="text--primary">
          {{ $t('disclaimerPage.acceptText') }}
        </span>
      </template>
    </v-checkbox>

    <div class="mt-6">
      <v-btn
        v-text="$t('cancel')"
        class="mr-3"
        depressed
        @click="logout"
      ></v-btn>
      <v-btn
        v-text="$t('next')"
        :disabled="!isAccepted"
        color="primary"
        depressed
        @click="submit"
      ></v-btn>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import { dateTimeFormats } from '@/constants'

import moment from 'moment'

import pageMixin from '@/mixins/page.mixin'
import authMixin from '@/mixins/auth.mixin'
import snackbarMixin from '@/mixins/snackbar.mixin'


export default {
  mixins: [
    pageMixin,
    authMixin,
    snackbarMixin
  ],


  data () {
    return {
      isAccepted: false,
      currentDealer: {}
    }
  },


  computed: {
    ...mapGetters('user', ['currentUser'])
  },


  methods: {
    ...mapActions('user', ['setDisclaimerRead', 'loadCurrentUser']),
    ...mapActions('dealer', ['loadDealerById']),

    async submit () {
      if (!this.isAccepted) return

      try {
        this.showSnackbarProcessing()

        await this.setDisclaimerRead(moment.utc().format(dateTimeFormats.dateTimeUtc))
        await this.loadCurrentUser()

        this.hideSnackbar()
        this.$router.push({ name: 'vehicles' })
      } catch (error) {
        this.showSnackbarError()
        this.$log.error(error)
      }
    }
  },

  async created () {
    try {
      this.setPageTitle('')

      if (this.currentUser.disclaimerReadDateUtc) {
        this.$router.push({ name: 'home' })
      }

      this.currentDealer = await this.loadDealerById({
        chainId: this.currentUser.chainId,
        id: this.currentUser.dealerId
      })
    } catch (error) {
      this.showSnackbarError()
      this.$log.error(error)
    }
  }
}
</script>
